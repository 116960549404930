<template>
  <div id="main"></div>
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";
import LocalStoreService from "../../service/LocalStoreService";

export default {
  name: "LogoutPage",
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.$msalInstance = new PublicClientApplication(
        this.$store.state.azure.msalConfig,
    )
    this.doRedirect(this.user);
  },
  methods: {
    doRedirect(user) {
      if (user && user.token) {
        LocalStoreService.removeUser();
      }
      try {
        this.$msalInstance.logout();
      } catch (error) {
          console.log(error)
      }
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
#main {
  background-color: #ffffff;
}
</style>
